import { v4 as uuidv4 } from 'uuid';
function chillHop() {
  return [
    {
      name: 'Spiral',
      cover:
        'https://chillhop.com/wp-content/uploads/2020/07/2a048a5780723e66fff64c3a60814ea64761284f-1024x1024.jpg',
      artist: 'Monma, Misha',
      audio: 'https://mp3.chillhop.com/serve.php/?mp3=8462',
      color: ['#EF8EA9', '#401f37'],
      id: uuidv4(),
      active: true,
    },
    {
      name: 'Today Feels Like Everyday',
      cover:
        'https://chillhop.com/wp-content/uploads/2021/01/6b1bb8736ee3e972747bc11f312e31cf7f5823e4-1024x1024.jpg',
      artist: 'Mama Aiuto',
      audio: 'https://mp3.chillhop.com/serve.php/?mp3=12125',
      color: ['#1b4f5d', '#58211e'],
      id: uuidv4(),
      active: false,
    },
    {
      name: 'Daylight',
      cover:
        'https://chillhop.com/wp-content/uploads/2020/07/ef95e219a44869318b7806e9f0f794a1f9c451e4-1024x1024.jpg',
      artist: 'Aiguille',
      audio: 'https://mp3.chillhop.com/serve.php/?mp3=9272',
      color: ['#EF8EA9', '#422039'],
      id: uuidv4(),
      active: false,
    },
    {
      name: 'Under the City Stars',
      cover:
        'https://chillhop.com/wp-content/uploads/2020/09/0255e8b8c74c90d4a27c594b3452b2daafae608d-1024x1024.jpg',
      artist: 'Aso, Middle School, Aviino',
      audio: 'https://mp3.chillhop.com/serve.php/?mp3=10074',
      color: ['#205950', '#2ab3bf'],
      id: uuidv4(),
      active: false,
    },
    {
      name: 'Keep Going',
      cover:
        'https://chillhop.com/wp-content/uploads/2020/07/ff35dede32321a8aa0953809812941bcf8a6bd35-1024x1024.jpg',
      artist: 'Swørn',
      audio: 'https://mp3.chillhop.com/serve.php/?mp3=9222',
      color: ['#CD607D', '#c94043'],
      id: uuidv4(),
      active: false,
    },
    {
      name: 'Going Back',
      cover:
        'https://chillhop.com/wp-content/uploads/2020/10/737bb830d34592344eb4a2a1d2c006cdbfc811d9-1024x1024.jpg',
      artist: 'Swørn',
      id: uuidv4(),
      audio: 'https://mp3.chillhop.com/serve.php/?mp3=10310',
      color: ['#365663', '#2b2a2f'],
      active: false,
    },
    {
      name: 'Nightfall',
      cover:
        'https://chillhop.com/wp-content/uploads/2020/07/ef95e219a44869318b7806e9f0f794a1f9c451e4-1024x1024.jpg',
      artist: 'Aiguille',
      audio: 'https://mp3.chillhop.com/serve.php/?mp3=9148',
      color: ['#EF8EA9', '#ab417f'],
      id: uuidv4(),
      active: false,
    },
    {
      name: 'Reflection',
      cover:
        'https://chillhop.com/wp-content/uploads/2020/07/ff35dede32321a8aa0953809812941bcf8a6bd35-1024x1024.jpg',
      artist: 'Swørn',
      audio: 'https://mp3.chillhop.com/serve.php/?mp3=9228',
      color: ['#CD607D', '#c94043'],
      id: uuidv4(),
      active: false,
    },
    {
      name: 'Far Away',
      cover:
        'https://chillhop.com/wp-content/uploads/2020/07/bc9a7af2ad67de703541c2c7a91e3df74ab494c0-1024x1024.jpg',
      artist: 'Swørn',
      id: uuidv4(),
      audio: 'https://mp3.chillhop.com/serve.php/?mp3=7951',
      color: ['#da8489', '#3e3550'],
      active: false,
    },
    {
      name: 'Pick Me Up',
      cover:
        'https://chillhop.com/wp-content/uploads/2020/07/bc9a7af2ad67de703541c2c7a91e3df74ab494c0-1024x1024.jpg',
      artist: 'Aso, Middle School, Aviino',
      id: uuidv4(),
      audio: 'https://mp3.chillhop.com/serve.php/?mp3=7949',
      color: ['#d84c57', '#4db9c6'],
      active: false,
    },
  ];
}

export default chillHop;
